<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <WindowTitleBar title="Stitching Creation" @loadData="loadData" @close_window="closeWindow" >{{setTitle()}}</WindowTitleBar>

      <div class="card-body">

        <div class="row">

          <div class="col-md-2">
            <div class="form-group form-group-material ">
              <label class="control-label text-semibold">Shift</label>
              <select id="cmbshift" ref="cmbshift" class="form-control" autofocus="" v-if="voucher"  v-model="voucher.shift_id" required>
                <option value="2">Day</option>
                <option value="3">Night</option>
              </select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Tailor</label>
              <select class="form-control" required="" v-if="voucher"  v-model="voucher.emp_id">
                <option v-for="machine in emps" v-bind:value="machine.id">
                  {{ machine.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-2">
          </div>

          <div class="col-md-4">
          </div>

          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label text-semibold">Date</label>
              <input type="date" class="form-control text-right" v-if="voucher" v-model="voucher.doc_date">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">

            <!-- Basic layout-->
            <div class="card">


              <div class="card-body">

                <!--  Detail Table -->
                <div class="row">
                  <div class="table-responsive">

                    <table class="table table-no-bordered">
                      <thead style="background-color: lightgrey">
                      <tr>
                        <th style="width: 100px;" >S.No</th>
                        <th>Quality</th>
                        <th>Color</th>
                        <th style="width: 120px;">Size</th>
                        <th style="text-align: right;width: 120px;">Pcs</th>
                        <th style="text-align: right;width: 120px;">Weight</th>
                        <th style="width: 30px;">Action</th>
                      </tr>
                      </thead>
                      <tbody v-for="(detail,index) in voucher.list" >
                        <Detail :myindex="index" :myrow="detail" :qualities = qualities :colors = colors v-on:onrow_change="onrow_change" v-on:onRemoveRow="removeRow" />
                      </tbody>
                      <tfoot>
                      <tr>
                        <td>
                          <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                            <i class="icon-plus3"></i>
                          </button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <!-- / Detail Table -->


              </div>
            </div>
            <!-- /basic layout -->

          </div>

        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>


          <div class="col-md-6">

          </div>

          <div class="col-md-3 text-right">

            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher" >Save</button>
            <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" >Clear</button>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import Detail from './StitchingDetailRow'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'StitchingForm',
  components: {
    WindowTitleBar,
    Detail
  },
  store,
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift_id":1,"emp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""}')
    }
  },
  beforeMount () {
    this.voucher = this.myvoucher;
  },
  data() {
    return {
      defects:[],
      machines: [],
      fab_weight: 0,
      damages: 0,
      emps:[],
      qualities : [],
      colors : [],
      voucher : JSON.parse('{"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""}'),
      mycard: {}
    }
  },
  created(){

  },
  mounted(){
    const self = this;
    self.$data.mycard = $('#mycard');

    if (self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }
    self.loadData();

    $('#cmbshift').focus();

    setTimeout( ()=> {
      // self.$data.voucher = {"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift_id":1,"emp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""};
    }, 1000);

  },
  filters:{
    weightFormat (val){
      return val.toFixed(3);
    }
  },
  methods:{
    setTitle() {
      return this.voucher.id == 0 ? "Stitching Creation" : "Doc No: " + this.voucher.doc_no + "  Stitching Updation";
    },
    closeWindow(){
      this.$emit('stitching_window_closed');
      if(this.voucher.id > 1){
        this.voucher = JSON.parse('{"id":0,"finyear":2000,"status":"Active","cmp_id":1,"shift_id":1,"emp_id":1,"shift":{"id":0,"type":0,"name":""},"emp":{"id":0,"name":""},"doc_date":"0001-01-01","list":[],"total_pcs":0,"remarks":""}');
      }else{
        this.$router.push("/")
      }
    },
    loadData(){
      this.loadEmps();
      this.loadQualities();
      this.loadColors();
    },
    addRow(){
      const self = this;
      let detail = {"idx":0,"id":0,"quality_id":1,"color_id":1,"pcs":0,"weight":0,"quality":{"id":1,"name":""},"color":{"id":1,"name":""},"size":""};

      self.$data.voucher.list.push(detail);

      setTimeout(function(){
        $("tbody>tr").last().find("td:eq(1) select").focus();
      }, 99);
    },
    onrow_change(rowIndex, colIndex, fabric) {
      const self = this;

      switch (colIndex){
        case 1:
          self.$data.voucher.list[rowIndex] = fabric;
          break;
        case 6:
          self.$data.voucher.list[rowIndex] = fabric;
          break;
      }

    },
    removeRow(index){
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1);
      }
    },
    loadQualities () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.qualities =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    loadColors () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.colors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/colors`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.colors =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    loadEmps(){

      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.machines = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/4`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if( !_.isNull(resp.data)){
            self.$data.machines = resp.data;
          }

        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
      });

      self.$data.emps = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok){
          if(  !_.isNull(resp.data)){
            self.$data.emps = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
      }).finally(function () {
        $('#mycard').unblock();
      });

    },
    saveVoucher(){
      const self = this;

      self.$data.voucher.finyear = store.state.user.finyear;
      self.$data.voucher.emp_id = parseInt(self.$data.voucher.emp_id);
      self.$data.voucher.shift_id = parseInt(self.$data.voucher.shift_id);
      self.$data.voucher.total_pcs = parseInt(self.$data.voucher.total_pcs);

      let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));

      myvoucher.id = self.$data.voucher.id;
      myvoucher.finyear = self.$store.state.user.finyear;
      myvoucher.status = "Active";
      myvoucher.cmp_id = self.$store.state.user.company.id;
      myvoucher.shift_id = parseInt(self.$data.voucher.shift_id);
      myvoucher.emp_id = parseInt(self.$data.voucher.emp_id);
      myvoucher.total_pcs = parseInt(self.$data.voucher.total_pcs);

      myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
      myvoucher.list = [];
      myvoucher.total_pcs = 0;

      self.$data.voucher.list.forEach(function (detail) {
        detail.color_id = parseInt(detail.color_id);
        detail.quality_id = parseInt(detail.quality_id);
        detail.qty = parseInt(detail.qty);
        myvoucher.list.push(detail);

        myvoucher.total_pcs += detail.qty;
      });


      if(myvoucher.emp_id < 1) {
        alert("Invalid Tailor");
        return
      }else if(myvoucher.shift_id < 1) {
        alert("Invalid Shift");
        return
      }else if(myvoucher.list.length < 1){
        alert("Invalid Detail");
        return
      }

      const requestOptions = {
        method:  (myvoucher.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(myvoucher)
      };

      // console.log(JSON.stringify(myvoucher));

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/stitching`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500,onClose: () => {
              $('#cmbshift').focus();
            }});
          self.$data.voucher.id = 0;
          self.$data.voucher.list = [];

          self.$emit('stitching_saved', resp.data);

        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString() , type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
